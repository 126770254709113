import './styles.scss';
import ResponsibleModal from './Modal';
import React, { useState } from 'react';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { connect } from 'react-redux';
import { IRootState } from 'src/Stores';
import { PartnerCreate } from '../../../../Models/Partner';
import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import { CommonLoading } from 'react-loadingg';
import { cpfMask } from 'src/Utils/Masks';
import moment from 'moment';

export interface ResponsibleProps extends StateToProps {
  responsibleStatus: HttpRequestStatus;
}

const ResponsibleData: React.FC<ResponsibleProps> = ({
  profile,
  partner,
  responsibleStatus,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const modalProps = {
    open,
    setOpen,
  };

  const LoadingContainer = () => {
    return (
      <div className="card-model" style={{ height: '300px' }}>
        <CommonLoading />
      </div>
    );
  };

  if (responsibleStatus === HttpRequestStatus.ONGOING)
    return LoadingContainer();

  var largura = window.screen.width;

  return (
    <div className="card-model">
      <div className="space-beetween profileCardHeader">
        <span className="value-title-responsible-data">
          Dados do responsável
        </span>
        <button onClick={handleOpen}>
          <CreateOutlinedIcon /> &nbsp;{' '}
          <span className="spanEditResponsibleData">Editar dados</span>
        </button>
      </div>
      <div className="setSpace">
        <div className="flexit mb-20">
          <div>
            <div className="flexx divider">
              <div className="userData2 width-100 ">
                <span className="super-title">Nome: {partner?.name}</span>
                <span className="letraCinza text-initial mb-10">
                  <strong>Nascimento:</strong>{' '}

                  {/* needs to be in moment.UTC because we save the date with hour 00:00, so if we do just "moment" it renders the previuos day */}
                  {moment.utc(new Date(partner?.birthDate)).format('DD/MM/YYYY')}

                </span>
                <span className="letraCinza text-initial mb-10">
                  <strong>CPF:</strong> {cpfMask(partner?.cpf)}
                </span>
                <span className="letraCinza text-initial mb-10">
                  <strong>Email:</strong> {partner?.user?.email}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>
      <ResponsibleModal {...modalProps} />
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { profile, responsibleStatus } = state?.profile;
  const { partner } = state?.authentication;

  return { profile, partner, responsibleStatus };
};

type StateToProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, {})(ResponsibleData);
